import {
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import {
  getDocumentById,
  getDocumentsByCollection,
  getDocumentsByKeyAndValue,
} from "..";
import { COLLECTIONS } from "../../config/constants";
import { db } from "../../config/firebase/firebase";
import { getWeddingDate } from "../../lib/weddingWebsite";
import { validateField } from "../../lib/weddingWebsiteValidations";
import { post } from "../../req";
import { PageIds, TemplateIds, WbSections } from "./constants";
import { processImage } from "./UploadImageToBucket";

const apiUrl = process.env.NEXT_PUBLIC_API_URL;

export const RefreshTypes = {
  REFRESH: "refresh",
  PAGE_NAV: "page-nav",
  PREVIEW: "preview",
};

export function refreshIframe({
  type = RefreshTypes.REFRESH,
  data = {},
  targetOrigin = "*",
} = {}) {
  const iframe = document.getElementById("template-preview");
  if (iframe) {
    iframe.contentWindow.postMessage({ type, data }, targetOrigin);
  }
}

export async function saveSelectedTemplateDetails(data) {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const weddingWebsiteRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );

    await setDoc(weddingWebsiteRef, data, { merge: true });

    refreshIframe();
    return { success: true };
  } catch (error) {
    console.error("Error storing selected template and color:", error);
    return { success: false, error };
  }
}

export async function getWeddingWebsiteInfo() {
  try {
    const { MainEventId: mainEventId } = JSON.parse(
      localStorage.getItem("userData")
    );

    const weddingWebsiteRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );
    const docSnap = await getDoc(weddingWebsiteRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching wedding website info:", error);
    return null;
  }
}

const templatePreviewImagePath = "/images/wedding-websites/template-preview";
export const weddingWebsites = [
  {
    id: TemplateIds.ROSE_BUD,
    name: "Rose Bud",
    editorPreviewImagePng: `${templatePreviewImagePath}/rose-bud.png`,
    editorPreviewImageWbp: `${templatePreviewImagePath}/rose-bud.webp`,
    url: `${TemplateIds.ROSE_BUD}/${PageIds.HOME}`,
    description:
      "Rose Bud is a beautiful and elegant template that is perfect for a romantic wedding.",
    colors: [
      { colorCode: "#33434A", colorClass: "rose-bud-theme1" },
      { colorCode: "#570F00", colorClass: "rose-bud-theme2" },
      { colorCode: "#510038", colorClass: "rose-bud-theme3" },
      { colorCode: "#020030", colorClass: "rose-bud-theme4" },
    ],
  },
  {
    id: TemplateIds.TOLEDO,
    name: "Toledo",
    editorPreviewImagePng: `${templatePreviewImagePath}/toledo.png`,
    editorPreviewImageWbp: `${templatePreviewImagePath}/toledo.webp`,
    url: `${TemplateIds.TOLEDO}/${PageIds.HOME}`,
    description:
      "Toledo is a beautiful and elegant template that is perfect for a romantic wedding.",
    colors: [
      { colorCode: "#663853", colorClass: "toledo-theme1" },
      { colorCode: "#28003A", colorClass: "toledo-theme2" },
      { colorCode: "#003B58", colorClass: "toledo-theme3" },
      { colorCode: "#520063", colorClass: "toledo-theme4" },
    ],
  },
];

async function refreshStaticPages({ customPageName = "", pageIds = [] }) {
  const queryParams = new URLSearchParams({
    customPageName,
    pageIds,
  });

  await post(
    `${
      process.env.NEXT_PUBLIC_BASE_URL
    }/api/revalidate?${queryParams.toString()}`
  );
}

export async function publishWeddingWebsite() {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const unpublishedRef = doc(
      db,
      COLLECTIONS.MainEvents,
      mainEventId,
      COLLECTIONS.WeddingWebsite,
      "unpublished"
    );

    const unpublishedDoc = await getDoc(unpublishedRef);

    if (unpublishedDoc.exists()) {
      const publishedData = unpublishedDoc.data();
      const { customPageName } = publishedData;

      const lastPublishedDate = new Date();

      delete publishedData.createdAt;
      publishedData.updatedAt = lastPublishedDate;
      publishedData.mainEventId = mainEventId;

      // Delete all other published docs with the same mainEventId
      const publishedDocs = await getDocumentsByKeyAndValue(
        COLLECTIONS.PublishedWeddingWebsites,
        "mainEventId",
        mainEventId
      );

      const deletePromises = publishedDocs
        .filter(i => i.id !== customPageName)
        .map(document =>
          deleteDoc(doc(db, COLLECTIONS.PublishedWeddingWebsites, document.id))
        );
      await Promise.all(deletePromises);

      // Publish the new doc or update the existing one
      await Promise.all([
        setDoc(
          doc(db, COLLECTIONS.PublishedWeddingWebsites, customPageName),
          publishedData,
          { merge: true }
        ),
        updateDoc(unpublishedRef, {
          lastPublishedDate,
          updatedAt: lastPublishedDate,
        }),
      ]);

      // create new static pages
      await refreshStaticPages({
        customPageName,
        pageIds: publishedData.pages.filter(p => p.visible).map(p => p.id),
      });

      return true;
    }
  } catch (error) {
    console.error("Error publishing wedding website:", error);
    return false;
  }
}

export async function getPublishedWeddingWebsite(customPageName) {
  let data = await getDocumentById(
    COLLECTIONS.PublishedWeddingWebsites,
    customPageName
  );

  if (!data.id) {
    return null;
  }

  // below object should have all the keys used in
  // getStaticProps of pages/[weddingWebsiteCustomPageName]/[pageId].js
  const keys = [
    { key: "color", type: "string" },
    { key: "customPageName", type: "string" },
    { key: "home", type: "object" },
    { key: "pages", type: "array" },
    { key: "sections", type: "object" },
    { key: "sectionState", type: "object" },
    { key: "templateId", type: "string" },
    { key: "sitePassword", type: "string" },
    { key: "requirePassword", type: "boolean" },
    { key: "domain", type: "object" },
  ];

  keys.forEach(({ key, type }) => {
    if (!(key in data) || data[key] === undefined) {
      switch (type) {
        case "string":
          data[key] = "";
          break;
        case "object":
          data[key] = {};
          break;
        case "array":
          data[key] = [];
          break;
        case "boolean":
          data[key] = null;
          break;
        default:
          data[key] = null;
      }
    }
  });

  function formatDates(obj) {
    function processValue(value) {
      if (value && typeof value.toDate === "function") {
        return getWeddingDate(value);
      }
      if (Array.isArray(value)) {
        return value.map(item => processValue(item));
      }
      if (typeof value === "object" && value !== null) {
        return formatDates(value);
      }
      return value;
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, processValue(value)])
    );
  }

  data = formatDates(data);

  return data;
}

export function getWeddingWebsiteCustomPaths(customPageName, pageIds) {
  const constantPageIds = [
    PageIds.HOME,
    PageIds.OUR_STORY,
    PageIds.PHOTOS,
    PageIds.WEDDING_PARTY,
    PageIds.RSVP,
    PageIds.QA,
    PageIds.REGISTRY,
    PageIds.THINGS_TO_DO,
    PageIds.TRAVEL,
  ];

  const allPageIds = [...new Set([...constantPageIds, ...pageIds])].filter(
    pageId => pageId !== PageIds.PRIVACY_URL && !pageId.startsWith("custom")
  );

  return allPageIds.map(pageId => `/${customPageName}/${pageId}`);
}

export async function getAllWeddingWebsiteCustomPageNames() {
  const publishedSites = await getDocumentsByCollection(
    COLLECTIONS.PublishedWeddingWebsites
  );

  return publishedSites
    .map(siteDoc =>
      getWeddingWebsiteCustomPaths(
        siteDoc.id,
        siteDoc.pages.filter(p => p.visible).map(p => p.id)
      )
    )
    .flat();
}

export async function customPageAvailable(customPageName) {
  const mainEventId = JSON.parse(localStorage.getItem("userData"))?.MainEventId;

  const ref = query(
    collectionGroup(db, COLLECTIONS.WeddingWebsite),
    where("customPageName", "==", customPageName),
    where("mainEventId", "!=", mainEventId)
  );

  const querySnapshot = await getDocs(ref);

  return querySnapshot.empty;
}

export function copyWebsiteUrl(customPageName, domain) {
  let url = "";
  const domainPurchased = domain?.purchased && domain?.domain;
  if (domainPurchased) {
    url = `${domainPurchased}`;
  } else {
    url = `${window.location.origin}/${customPageName}/home`;
  }
  navigator.clipboard.writeText(url);
}

export async function processSections(sections, sectionState, selectedPage) {
  const sectionPromises = sections.map(async (type, index) => {
    const section = { ...sectionState[index] };
    const uniqueKey = `${selectedPage}_${type}_${index}`;
    section.key = uniqueKey;

    switch (type) {
      case WbSections.Activity.id:
      case WbSections.Hotel.id:
      case WbSections.Story.id:
      case WbSections.Transport.id:
      case WbSections.Person.id:
        section.image = await processImage(section.image);
        break;

      case WbSections.Photo.id:
        section.images = await Promise.all(
          section.images.map(async (image, imgIndex) => ({
            ...image,
            key: `${uniqueKey}_image_${imgIndex}`,
            src: await processImage(image.src),
          }))
        );
        break;

      case WbSections.PhotoTimeline.id:
        section.photoMemoryItems = await Promise.all(
          section.photoMemoryItems.map(async (item, itemIndex) => ({
            ...item,
            key: `${uniqueKey}_item_${itemIndex}`,
            image: await processImage(item.image),
          }))
        );
        break;

      case WbSections.GIf.id:
        section.gif = await processImage(section.gif);
        break;
    }

    return section;
  });

  return await Promise.all(sectionPromises);
}

export function validateAllCustomFields(sections, sectionState, selectedPage) {
  const validateSections = sections[selectedPage] || [];
  const sectionStateForPage = sectionState[selectedPage] || [];
  // If validateSections is empty, return isValid as true
  if (validateSections.length === 0) {
    return { errors: {}, isValid: true };
  }

  const errors = validateSections.map((sectionType, index) => {
    const sectionValidationVars =
      WbSections[sectionType]?.validationVariables || [];

    const sectionErrors = sectionValidationVars.map(value => {
      const sectionData =
        sectionStateForPage[index]?.photoMemoryItems ||
        sectionStateForPage[index]?.images ||
        sectionStateForPage[index];
      if (Array.isArray(sectionData)) {
        if (sectionData.length === 0) {
          return [{ [value]: { itemError: "No items found" } }];
        }
        const arrayErrors = sectionData.map(item => {
          const itemError = validateField(
            value,
            item[value] || item["src"] || "",
            sectionType
          );

          return itemError ? { [value]: { itemError } } : null;
        });
        return arrayErrors.filter(Boolean);
      } else {
        // If sectionData is not an array, validate it directly
        const error = validateField(
          value,
          sectionData?.[value] || "",
          sectionType
        );
        return error ? { [value]: error } : null;
      }
    });

    const filteredSectionErrors = sectionErrors.flat().filter(Boolean);

    return filteredSectionErrors.length > 0
      ? { [index]: Object.assign({}, ...filteredSectionErrors) }
      : null;
  });

  const filteredErrors = errors.filter(Boolean);

  return {
    errors: Object.assign({}, ...filteredErrors),
    isValid: filteredErrors.length === 0,
  };
}

export async function checkDomainAvailability(domain) {
  try {
    const response = await post(`${apiUrl}/domain/check-availability`, {
      domain,
    });
    return response;
  } catch (error) {
    console.error("Error checking domain availability:", error);
    return {
      available: false,
      error:
        error?.message ||
        "Something went wrong while checking domain availability!",
    };
  }
}

export async function generateCheckoutUrl() {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const response = await post(`${apiUrl}/domain/generate-payment-url`, {
      mainEventId,
    });
    return { url: response?.body?.data?.url };
  } catch (error) {
    return {
      error: error?.message || "Something went wrong!",
    };
  }
}

export async function purchaseDomain() {
  try {
    const mainEventId = JSON.parse(
      localStorage.getItem("userData")
    )?.MainEventId;

    const mainEventInfo = await getDocumentById(
      COLLECTIONS.MainEvents,
      mainEventId
    );

    if (mainEventInfo?.domain?.purchased) {
      return { success: true, alreadyPurchased: true };
    }

    const response = await post(`${apiUrl}/domain/purchase`, {
      mainEventId,
    });

    if (response?.statusCode === 200) {
      return { success: true };
    }

    return { success: false, error: response?.body?.message };
  } catch (error) {
    console.error("Error purchasing domain:", error);
    return {
      success: false,
      error: error?.message || "Something went wrong!",
    };
  }
}

export function showNavigation(pages, onTemplateDemo) {
  if (onTemplateDemo) {
    return true;
  }

  const visiblePageCount =
    pages
      ?.filter(page => ![PageIds.HOME, PageIds.PRIVACY_URL].includes(page.id))
      ?.filter(page => page.visible)?.length ?? 0;

  return visiblePageCount > 0;
}

export function openUrl(website, newTab = true) {
  const formattedWebsite =
    website.startsWith("http://") || website.startsWith("https://")
      ? website
      : `https://${website}`;

  window.open(formattedWebsite, newTab ? "_blank" : "_self");
}
