import dynamic from "next/dynamic";

import { setSectionState } from "../../store/weddingWebsite/weddingWebsite";

// Group editor components
const editorComponents = {
  WbaddLivestreamLink: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/live-stream/WbaddLivestreamLink"
    )
  ),
  WbAddActivity: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddActivity"
    )
  ),
  WbAddGif: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddGif"
    )
  ),
  WbAddHotel: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddHotel"
    )
  ),
  WbAddPerson: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddPerson"
    )
  ),
  WbAddPhoto: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddPhoto"
    )
  ),
  WbAddPhotoMemory: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddPhotoMemory"
    )
  ),
  WbAddStory: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddStory"
    )
  ),
  WbAddTitle: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddTitle"
    )
  ),
  WbAddTransport: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbAddTransport"
    )
  ),
  WbQnA: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbQnA"
    )
  ),
  WbTextBlock: dynamic(() =>
    import(
      "../../pages/wedding-website/template-editor/_editor-components/WbTextBlock"
    )
  ),
};

// Group shared components
const sharedComponents = {
  ActivityView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/ActivityView"
    )
  ),
  GifView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/GifView"
    )
  ),
  HotelView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/HotelView"
    )
  ),
  LiveStreamView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/LiveStreamView"
    )
  ),
  MediaView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/PhotoGallery"
    )
  ),
  PersonView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/PersonView"
    )
  ),
  QandAView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/QandAView"
    )
  ),
  StoryView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/StoryView"
    )
  ),
  TextBlockView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TextBlockView"
    )
  ),
  TimeLineView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TimeLineView"
    )
  ),
  TitleView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TitleView"
    )
  ),
  TransportView: dynamic(() =>
    import(
      "../../pages/wedding-website/wedding-templates/_template-components/shared/TransportView"
    )
  ),
};

export const TemplateSubtitle =
  "A collection of our favorite moments captured over the years. ";

export const WbSections = {
  Title: {
    id: "Title",
    validationVariables: ["titleText"],
    renderComponent: sharedComponents.TitleView,
    editor: editorComponents.WbAddTitle,
    handlers: (dispatch, index) => {
      return {
        onTitleTypeChange: titleType =>
          dispatch(setSectionState({ index, data: { titleType } })),
        onTitleTextChange: titleText =>
          dispatch(setSectionState({ index, data: { titleText } })),
      };
    },
  },

  TextBlock: {
    id: "TextBlock",
    validationVariables: ["titleText", "bodyText"],
    renderComponent: sharedComponents.TextBlockView,
    editor: editorComponents.WbTextBlock,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: titleText =>
          dispatch(setSectionState({ index, data: { titleText } })),
        onBodyChange: bodyText =>
          dispatch(setSectionState({ index, data: { bodyText } })),
      };
    },
  },

  Photo: {
    id: "Photo",
    validationVariables: ["image"],
    renderComponent: sharedComponents.MediaView,
    editor: editorComponents.WbAddPhoto,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: images => {
          dispatch(setSectionState({ index, data: { images } }));
        },
        handleDeleteImageClick: images => {
          dispatch(setSectionState({ index, data: { images } }));
        },
      };
    },
  },

  GIf: {
    id: "GIf",
    validationVariables: ["gif"],
    renderComponent: sharedComponents.GifView,
    editor: editorComponents.WbAddGif,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: gif => {
          dispatch(setSectionState({ index, data: { gif } }));
        },
        handleDeleteGif: () => {
          dispatch(setSectionState({ index, data: { gif: null } }));
        },
      };
    },
  },

  PhotoTimeline: {
    id: "PhotoTimeline",
    validationVariables: ["date", "description", "image"],
    renderComponent: sharedComponents.TimeLineView,
    editor: editorComponents.WbAddPhotoMemory,
    handlers: (dispatch, index) => {
      return {
        handleFileUpload: photoMemoryItems => {
          dispatch(setSectionState({ index, data: { photoMemoryItems } }));
        },
        handleDeletePhotoMemory: photoMemoryItems => {
          dispatch(setSectionState({ index, data: { photoMemoryItems } }));
        },
      };
    },
  },

  LiveStream: {
    id: "LiveStream",
    validationVariables: ["title", "buttonText", "livestreamLink"],
    renderComponent: sharedComponents.LiveStreamView,
    editor: editorComponents.WbaddLivestreamLink,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onButtonTextChange: buttonText =>
          dispatch(setSectionState({ index, data: { buttonText } })),
        onLivestreamLinkChange: livestreamLink =>
          dispatch(setSectionState({ index, data: { livestreamLink } })),
      };
    },
  },

  Story: {
    id: "Story",
    validationVariables: ["title", "description"],
    renderComponent: sharedComponents.StoryView,
    editor: editorComponents.WbAddStory,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },

  Person: {
    id: "Person",
    validationVariables: ["name", "role"],
    renderComponent: sharedComponents.PersonView,
    editor: editorComponents.WbAddPerson,
    handlers: (dispatch, index) => {
      return {
        handleDeletePerson: () => {
          dispatch(setSectionState({ index, data: { person: null } }));
        },
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onRoleChange: role =>
          dispatch(setSectionState({ index, data: { role } })),
        onDescriptionChange: description =>
          dispatch(setSectionState({ index, data: { description } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        handleImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  QnA: {
    id: "QnA",
    validationVariables: ["question", "answer"],
    renderComponent: sharedComponents.QandAView,
    editor: editorComponents.WbQnA,
    handlers: (dispatch, index) => {
      return {
        onQuestionChange: question =>
          dispatch(setSectionState({ index, data: { question } })),
        onAnswerChange: answer =>
          dispatch(setSectionState({ index, data: { answer } })),
      };
    },
  },
  Transport: {
    id: "Transport",
    validationVariables: [
      "title",
      "image",
      "email",
      "address",
      "phone",
      "website",
    ],
    renderComponent: sharedComponents.TransportView,
    editor: editorComponents.WbAddTransport,
    handlers: (dispatch, index) => {
      return {
        onTitleChange: title =>
          dispatch(setSectionState({ index, data: { title } })),
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  Activity: {
    id: "Activity",
    validationVariables: [
      "name",
      "image",
      "email",
      "address",
      "phone",
      "website",
    ],
    renderComponent: sharedComponents.ActivityView,
    editor: editorComponents.WbAddActivity,
    handlers: (dispatch, index) => {
      return {
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
      };
    },
  },
  Hotel: {
    id: "Hotel",
    validationVariables: [
      "name",
      "image",
      "phone",
      "address",
      "email",
      "website",
      "roomRate",
      "blockCode",
    ],
    renderComponent: sharedComponents.HotelView,
    editor: editorComponents.WbAddHotel,
    handlers: (dispatch, index) => {
      return {
        onAddressChange: address =>
          dispatch(setSectionState({ index, data: { address } })),
        onBlockCodeChange: blockCode =>
          dispatch(setSectionState({ index, data: { blockCode } })),
        onCheckInDateChange: checkInDate =>
          dispatch(setSectionState({ index, data: { checkInDate } })),
        onCheckOutDateChange: checkOutDate =>
          dispatch(setSectionState({ index, data: { checkOutDate } })),
        onDetailsChange: details =>
          dispatch(setSectionState({ index, data: { details } })),
        onEmailChange: email =>
          dispatch(setSectionState({ index, data: { email } })),
        onImageUpload: image =>
          dispatch(setSectionState({ index, data: { image } })),
        onImageRemove: () =>
          dispatch(setSectionState({ index, data: { image: null } })),
        onNameChange: name =>
          dispatch(setSectionState({ index, data: { name } })),
        onPhoneChange: phone =>
          dispatch(setSectionState({ index, data: { phone } })),
        onRoomRateChange: roomRate =>
          dispatch(setSectionState({ index, data: { roomRate } })),
        onWebsiteChange: website =>
          dispatch(setSectionState({ index, data: { website } })),
      };
    },
  },
};

export const PageIds = {
  HOME: "home",
  OUR_STORY: "our-story",
  PHOTOS: "photos",
  WEDDING_PARTY: "wedding-party",
  RSVP: "rsvp",
  PRIVACY_URL: "privacy-url",
  QA: "qa",
  REGISTRY: "registry",
  THINGS_TO_DO: "things-to-do",
  TRAVEL: "travel",
  ADD_NEW_PAGE: "add-new-page",
};

export const TemplateIds = {
  ROSE_BUD: "rose-bud",
  TOLEDO: "toledo",
};

export const PageIdToText = {
  [PageIds.HOME]: "Home",
  [PageIds.OUR_STORY]: "Our Story",
  [PageIds.PHOTOS]: "Photos",
  [PageIds.WEDDING_PARTY]: "Wedding Party",
  [PageIds.RSVP]: "RSVP",
  [PageIds.PRIVACY_URL]: "Privacy & URL",
  [PageIds.QA]: "Q + A",
  [PageIds.REGISTRY]: "Registry",
  [PageIds.THINGS_TO_DO]: "Things to do",
  [PageIds.TRAVEL]: "Travel",
};

export const WebsiteEditorPages = {
  HOME: { text: "Home", id: PageIds.HOME },
  OUR_STORY: { text: "Our Story", id: PageIds.OUR_STORY },
  PHOTOS: { text: "Photos", id: PageIds.PHOTOS },
  WEDDING_PARTY: { text: "Wedding Party", id: PageIds.WEDDING_PARTY },
  RSVP: { text: "RSVP", id: PageIds.RSVP },
  PRIVACY_URL: { text: "Privacy & URL", id: PageIds.PRIVACY_URL },
  QA: { text: "Q + A", id: PageIds.QA },
  REGISTRY: { text: "Registry", id: PageIds.REGISTRY },
  THINGS_TO_DO: { text: "Things to do", id: PageIds.THINGS_TO_DO },
  TRAVEL: { text: "Travel", id: PageIds.TRAVEL },
  ADD_NEW_PAGE: { text: "Add New Page", id: "add-new-page" },
  CUSTOME_SECTION: { text: "Custom Section", id: "custom-section" },
};

export const WbPages = [
  {
    id: PageIds.HOME,
    name: WebsiteEditorPages.HOME.text,
    visible: true,
  },
  {
    id: PageIds.OUR_STORY,
    name: WebsiteEditorPages.OUR_STORY.text,
    visible: false,
  },
  {
    id: PageIds.PHOTOS,
    name: WebsiteEditorPages.PHOTOS.text,
    visible: false,
  },
  {
    id: PageIds.WEDDING_PARTY,
    name: WebsiteEditorPages.WEDDING_PARTY.text,
    visible: false,
  },
  {
    id: PageIds.RSVP,
    name: WebsiteEditorPages.RSVP.text,
    visible: false,
  },
  {
    id: PageIds.QA,
    name: WebsiteEditorPages.QA.text,
    visible: false,
  },
  {
    id: PageIds.REGISTRY,
    name: WebsiteEditorPages.REGISTRY.text,
    visible: false,
  },
  {
    id: PageIds.THINGS_TO_DO,
    name: WebsiteEditorPages.THINGS_TO_DO.text,
    visible: false,
  },
  {
    id: PageIds.TRAVEL,
    name: WebsiteEditorPages.TRAVEL.text,
    visible: false,
  },
  {
    id: PageIds.PRIVACY_URL,
    name: WebsiteEditorPages.PRIVACY_URL.text,
    visible: true,
  },
];

export const CreateWeddingWebsite = "Create Wedding Website";

// if we need to add more custom pages, we can add them here and also in next.config.js
export const CustomPageIds = [
  "custom1",
  "custom2",
  "custom3",
  "custom4",
  "custom5",
  "custom6",
  "custom7",
  "custom8",
  "custom9",
  "custom10",
];

export function getCustomPageId(pages) {
  const existingPageIds = pages.map(page => page.id);

  const availablePageIds = CustomPageIds.filter(
    id => !existingPageIds.includes(id)
  );
  if (!availablePageIds.length) {
    return null;
  }

  return availablePageIds[0];
}

export const WeddingPartyDummyData = Array(4).fill({
  name: "Priya",
  role: "made of honour",
  description: "<p class=text-center>Description</p>",
  image: "/images/wedding-websites/upload-photo.png",
});

export const PhotoGalleryDummayData = [

  { src: "/images/wedding-websites/rose-bud1.png", key: "dummy-img1" },
  { src: "/images/wedding-websites/rose-bud3.png", key: "dummy-img2" },
  { src: "/images/wedding-websites/rose-bud2.png", key: "dummy-img3" },
  { src: "/images/wedding-websites/rose-bud5.png", key: "dummy-img4" },
  { src: "/images/wedding-websites/rose-bud4.png", key: "dummy-img5" },
];
