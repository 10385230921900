import { createSlice } from "@reduxjs/toolkit";

import { getCustomPageName } from "../../models/weddingWebsiteTemplates/initialData";

const initialState = {
  selectedTemplateId: "rose-bud",
  previewTemplateId: null,
  selectedColor: "",
  onTemplateDemo: false,
  pages: [],
  home: {
    firstName: "",
    partnerFirstName: "",
    lastName: "",
    partnerLastName: "",
    eventName: "",
    date: "",
    startTime: "",
    endTime: "",
    venueName: "",
    attire: "",
    description: "",
    isOpenToAll: true,
    coverTitle: "",
    coverText: "",
    weddingDate: "",
    weddingLocation: "",
    announcement: "",
    announcementVisibility: true,
  },
  selectedClass: "",
  selectedPage: null,
  customPageName: null,

  appearInSeachEngine: true,
  requirePassword: false,
  sitePassword: "",

  // for adding custom sections to the page
  sections: {},
  sectionState: {},
  isPublished: false,

  domain: null,
  fetchingData: false,
};

export const weddingWebsiteSlice = createSlice({
  name: "weddingWebsite",
  initialState,
  reducers: {
    updateTemplateValues: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCurrentTemplate: (state, action) => {
      const { templateId, color } = action.payload;

      const requiredKeys = ["templateId", "color"];
      const missingKeys = requiredKeys.filter(key => !(key in action.payload));
      if (missingKeys.length) {
        throw new Error(`Missing required keys: ${missingKeys.join(", ")}`);
      }

      state.selectedTemplateId = templateId;
      state.previewTemplateId = null;

      state.selectedColor = color;

      const keys = [
        "appearInSeachEngine",
        "customPageName",
        "domain",
        "home",
        "isPublished",
        "onTemplateDemo",
        "pages",
        "requirePassword",
        "sections",
        "sectionState",
        "selectedPage",
        "sitePassword",
      ];

      keys.forEach(key => {
        if (key in action.payload) {
          if (!state.customPageName && key === "home") {
            // DOTO check if the page is available(not taken by any other user) and then set the custom page name
            state.customPageName = getCustomPageName(action?.payload?.home);
          }

          state[key] = action.payload[key];
        }
      });

      state.fetchingData = false;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    unPublished: (state, action) => {
      state.unPublished = action.payload;
    },
    addSection: (state, action) => {
      if (!state.sections[state.selectedPage]) {
        state.sections[state.selectedPage] = [];
        state.sectionState[state.selectedPage] = [];
      }
      state.sections[state.selectedPage].push(action.payload);
      state.sectionState[state.selectedPage].push({});
    },
    removeSection: (state, action) => {
      state.sections[state.selectedPage].splice(action.payload, 1);
      state.sectionState[state.selectedPage].splice(action.payload, 1);
    },
    setSectionState: (state, action) => {
      const { index, data } = action.payload;

      const oldState = state.sectionState[state.selectedPage][index] || {};

      state.sectionState[state.selectedPage][index] = {
        ...oldState,
        ...data,
      };
    },
    updatePageSections: (state, action) => {
      const { sections, sectionState } = action.payload;

      const page = state.selectedPage;
      state.sections[page] = sections;
      state.sectionState[page] = sectionState;
    },
    setPages: (state, action) => {
      state.pages = action.payload;
    },
    setPageVisibility: (state, action) => {
      const { id, visible } = action.payload;
      const page = state.pages.find(page => page.id === id);
      page.visible = visible;
    },
    setFetchingData: (state, action) => {
      state.fetchingData = action.payload;
    },
  },
});

export const {
  updateTemplateValues,
  setCurrentTemplate,
  setSelectedPage,
  unPublishedTemplate,
  addSection,
  removeSection,
  setSectionState,
  updatePageSections,
  setPages,
  setPageVisibility,
  setFetchingData,
} = weddingWebsiteSlice.actions;

export default weddingWebsiteSlice.reducer;
