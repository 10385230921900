export const COLLECTIONS = {
  activityLogs: 'activityLogs',
  Admins: 'Admins',
  Budget: 'Budget',
  Cities: 'Cities',
  Contracts: 'Contracts',
  EventTemplates: 'EventTemplates',
  Faqs: 'Faqs',
  FavouriteVendors: 'FavouriteVendors',
  GlobalSettings: 'GlobalSettings',
  MainEvents: 'MainEvents',
  MasterEvents: 'MasterEvents',
  MasterPlanningEmai1s: 'MasterPlanningEmai1s',
  MasterTags: 'MasterTags',
  MasterTodoLists: 'MasterTodoLists',
  Meetings: 'Meetings',
  MyVendorCategory: 'MyVendorCategory',
  Notifications: 'Notifications',
  NotificationUsers: 'NotificationUsers',
  QuizzResponses: 'QuizzResponses',
  References: 'References',
  rejectedVendor: 'rejectedVendor',
  Religions: 'Religions',
  SelfManagedVendor: 'SelfManagedVendor',
  SocialPages: 'SocialPages',
  States: 'States',
  Styles: 'Styles',
  SubRe1igions: 'SubRe1igions',
  subscriptionP1ans: 'subscriptionP1ans',
  SubscriptionP1ans: 'SubscriptionP1ans',
  SuggestedVendors: "SuggestedVendors",
  systemConfig: 'systemConfig',
  SystemConfig: 'SystemConfig',
  templatesNotifications: 'templatesNotifications',
  TermsAndConditions: 'TermsAndConditions',
  Users: 'Users',
  VendorCategories: 'VendorCategories',
  Vendors: 'Vendors',
  weddingWebsiteTemplates: 'weddingWebsiteTemplates',
  WeddingWebsite: 'WeddingWebsite',
  PublishedWeddingWebsites: 'PublishedWeddingWebsites',
}

export const ReceptionEventDocId = "reception"

export const Routes = {
  CREATE_PROFILE: "/Authentication/create-profile-new",
  DASHBOARD: "/Dashboard",
  INVITE_PARTNER: "/Authentication/invite-partner",
  JOINS_US: "/Authentication/SignUp/SignupLanding",
  SUBSCRIPTION_PLANS: "/Subscriptions/Plans",
  VERIFY_EMAIL: "/Authentication/create-profile/verify-email",
  VERIFY_MOBILE: "/Authentication/create-profile/verify-user",
  LOGIN: "/Authentication/login",
  MY_CONTRACTS: "/Contracts",
}

export const Messages = {
  skipped: "skipped",
  contractPendingProfile: "Please complete your profile before requesting a proposal.",
  contractPartnerInvite: "Please invite your partner before requesting a proposal.",
  contractPartnerAndProfilePending: "Please ensure both your profile and partner information are complete before requesting a proposal.",
  conirmWeddingText:`Have you booked a venue? To upload other vendor contracts, start by adding your Venue contract on the `,
  NoCategoryVendors: "You don't have any recommendations, yet. Please select a category and complete the quiz to receive recommendations.",
  NoFavoriteVendors:"You haven't favorited any wedding pros yet! Begin by exploring recommendations and tapping the heart icon on the pros that catch your eye. Your dream team will gather right here, ready for you to reach out and start booking the ones you love!",
  christianWeddingCompleted:"Vivahathinu Aashamsakal",
  hinduWeddingCompleted:"Vivah par Badhai",
  muslimWeddingCompleted:"Shadi Mubarak",
  sikhWeddingCompleted:"Vadhaiyan"
}
export const cellPhone = 'Cell phone'

export const CallTypes = {
  firstCall: "first-call",
}


export const personalityTypeConfig = {
  "Water": {
      text: "Sparkling Water",
      image: "/images/personality/glass-water.png",
      url: "https://desiweds.com/wedding-couple-personality-quiz-water/"
  },
  "Champagne": {
      text: "Champagne Toast",
      image: "/images/personality/champagne-toast.png",
      url: "https://desiweds.com/wedding-couple-personality-quiz-champagne/"
  },
  "Chai": {
      text: "Cup of Chai",
      image: "/images/personality/chai-tea.png",
      url: "https://desiweds.com/wedding-couple-personality-quiz-cup-of-chai/"
  },
  "Wine": {
      text: "Glass of Wine",
      image: "/images/personality/glass-wine.png",
      url: "https://desiweds.com/wedding-couple-personality-quiz-wine-glass/"
  },
  "Whiskey": {
      text: "Shot of Whiskey",
      image: "/images/personality/whiskey.png",
      url: "https://desiweds.com/wedding-couple-personality-quiz-whiskey-shot/"
  },
};

export const  glamResultUrl ={
  Eclectic:"https://desiweds.com/decor-style-explained-eclectic/",
  Glam:"https://desiweds.com/decor-style-explained-glam/",
  Modern:"https://desiweds.com/decor-style-explained-modern/",
  Romantic:"https://desiweds.com/decor-style-explained-romantic/",
}



export const paletteData = [
  ["#183B59", "#CF33D7", "#F1ADDB", "#D0B7DB"],
  ["#531322", "#E1BDB9", "#E5ACAD", "#365416"],
  ["#7A8C95", "#F9B77C", "#F4ECA8", "#F9B77C"],
  ["#271E1A", "#F7F7F7", "#517E22", "#E7A60C"],
  ["#50392E", "#F4E775", "#77905C", "#DE6C2E"],
  ["#521F22", "#F4D2C6", "#8CA571", "#F7EAEA"],
  ["#FB800E", "#BE68C9", "#C8D7B7", "#E949C6"],
].map((colors, index) => ({
  colors,
  image: `/images/color/color-palette-${index + 1}.png`,
}));

export const vendorDefaultLogo = "https://firebasestorage.googleapis.com/v0/b/dev-desiweds.appspot.com/o/public%2Fcommon%2FGroup%2028303.png?alt=media&token=b71167ce-bb6c-4272-aa8c-51c89dfdaffa";

export const UsDateFormat = "MM-DD-YYYY";

export const weddingWebsiteCustomValidation={
  imageMaxSize:5,
  imageMinSize:.5,
  gifMaxSize:5,
  emailValidation:"Invalid email format",
  phoneNumberValidation:"Phone number must be 10 digits.",
  imageValidationMessages:{
    imagesOutsideAllowedSize: "Image size should be between 500Kb and 5MB",
    lessThanMinSize:"The file size is too small. Image should be minimum 500Kb",
    greaterThanMaxSize:"The file size is too large. The allowed maximum size is 5MB",
    imageNotExist:"Please upload an image",
    invalidFormat:"Invalid file format. Please upload an image",
    ignored: {
      imagesOutsideAllowedSize:"Images smaller then 500Kb or greater then 5MB is ignored",
      smallerImages: "Images smaller then 500Kb is ignored",
      largerImages: "Images greater then 5MB is ignored"
    }
  },
  gifValidationMessages:{
    lessThanMinSize:"The file size is too small. Image should be minimum 500Kb",
    greaterThanMaxSize:"The file size is too large. The allowed maximum size is 5MB",
    gifNotExist:"Please upload a GIF",
    invalidFormat:"Invalid file format. Please upload a GIF"
  },
  textValueNotExist:"cannot be empty",
  charactorLength:"must be 120 characters or less",
  privacyValidateMessages:
  {
    pinRequired:"PIN is required.",
    invalidCharactors:"Invalid characters. Please use only numbers for the PIN.",
    invalidLength:"Invalid PIN length. Please enter a 4-6 digit numeric PIN.",
    repeatedCharactors:"PIN should not contain repeated digits.",
    sequentialDigits:"PIN should not contain sequential digits."

  },
  websiteUrlValidationMessages:
  {
    short:"The URL is too short. Domain names must contain at least 2 characters before the extension.",
    long:"The URL is too long. Domain names cannot exceed 63 characters. Please shorten the domain.",
    specialCharactors:"URLs cannot contain special characters such as !, @, #, $, %, ^, &, *, (, ), etc. Please remove any special characters.",
    space:"URLs cannot contain spaces. Please remove spaces or use hyphens (-) to separate words.",
    dots:"URLs cannot contain consecutive dots (..). Please remove or replace extra dots.",
    dash:"URLs cannot start or end with a dash (-). Please remove any leading or trailing dashes."

  },
  liveSectionValidateMessages:
  {
    btnMinLength:"Button text must be at least 1 character long.",
    btnMaxLength:"Button text cannot exceed 30 characters."
  },
  hotelValidationMessages: {
    blockCodeTooLong: "Block code cannot exceed 15 characters.",
    blockCodeNotAlphanumeric: "Block code must be alphanumeric.",
    roomRateTooLong: "Rate cannot exceed 6 digits.",
    roomRateNotNumeric: "Rate must be a numerical value."
  },
  personValidationMessages: {
    nameAlphabetsOnly: "Only alphabets are allowed for the name",
    nameMinLength: "Name must be at least 3 characters long",
    roleAlphabetsOnly: "Only alphabets are allowed for the role",
    roleMinLength: "Role must be at least 3 characters long"
  }
};
